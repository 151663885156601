
import { Component, Vue } from "vue-property-decorator";

import RequestForm from "../../components/RequestFormComponent.vue";

@Component({
  components: {
    RequestForm
  }
})
export default class RequestCreatePage extends Vue {
  formData = {
    date_from: null,
    date_to: null
  };

  confirmation = false;
  errors = null;

  request() {
    this.errors = null;

    this.$store
      .dispatch("vacation/request", this.formData)
      .then(() => (this.confirmation = true))
      .catch(error => (this.errors = error.response.data.errors));
  }

  confirm() {
    this.$router.push({
      name: "vacation.requests.index"
    });
  }
}
